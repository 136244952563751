import { Layout, Row, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import LiveIcon from "../../../assets/icons/home-live-button.svg";
import Popcorn from "../../../assets/images/popcorn.webp";
import LiveGiftVideo from "../../../assets/videos/live-gift.mp4";
import LiveGiftImg from "../../../assets/images/live-gift-img.webp";
import FanHomeSuggestionComponent from "../../../components/FanHomeSuggestionComponent";
import FanPhoneModalComponent from "../../../components/FanPhoneModalComponent";
import FooterComponent from "../../../components/FooterComponent";
import MainSwiper from "../../../components/MainSwiper";
import { FOOTER_IDS } from "../../../helpers/constant";
import { IUser } from "../../../helpers/types";
import { StoreState } from "../../../redux/store";
import { authService, userService } from "../../../services";
import "./index.less";

const FanHomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user: IUser = authService.getUser();
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [hasVideoPlayed, setHasVideoPlayed] = useState<boolean>(false);
  const [phoneModal, setPhoneModal] = useState<boolean>(false);
  const { homeFeeds } = useSelector((state: StoreState) => state.feeds);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    const videoPlayed = localStorage.getItem("hasVideoPlayed");

    if (video) {
      video.play();
    }

    if (videoPlayed) {
      setHasVideoPlayed(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("phoneModalShown") === null || !localStorage.getItem("phoneModalShown")) {
      userService.getPhoneModalShown().then((data) => {
        if (!data.phoneModalShown) {
          setPhoneModal(true);
        }
      });
    }
  }, [user]);

  const closePhoneModal = () => {
    setPhoneModal(false);
    userService.updatePhoneModalShown(true);
    localStorage.setItem("phoneModalShown", JSON.stringify(true));
  };

  useEffect(() => {
    if (localStorage.getItem("phoneModalShown") === null || !localStorage.getItem("phoneModalShown")) {
      userService.getPhoneModalShown().then((data) => {
        if (!data.phoneModalShown) {
          setPhoneModal(true);
        }
      });
    }
  }, [user]);

  const endVideo = () => {
    setIsVideoEnded(true);
    localStorage.setItem("hasVideoPlayed", "true");
  };

  return (
    <Layout>
      <Layout.Content className="header-background-fan fanHomeContentFeedWrapper no-scroll-horizontal">
        <div>
          <Row className="header-row" align="middle">
            <Typography.Text className="title-home-fan">
              {t("for-1")} {user.firstName}
            </Typography.Text>
            <img src={Popcorn} width={24} className="header-icon" alt="Loly-popcorn" />
            <div
              className="liveButton"
              onClick={() => {
                navigate("/p/fan/lives?tab=schedule");
              }}
            >
              <img src={LiveIcon} className="mr-7" width={14} alt="Loly-popcorn" />
              <Typography.Text className="text-white-color font-14-bold">LIVE</Typography.Text>
            </div>
          </Row>
          <div className="live-gift-container">
            {!hasVideoPlayed ? (
              <>
                <video
                  ref={videoRef}
                  src={LiveGiftVideo}
                  width="100%"
                  autoPlay
                  muted
                  playsInline
                  loop={false}
                  onEnded={endVideo}
                  style={{ display: "block", objectFit: "cover", backgroundColor: "#111111" }}
                />
                {isVideoEnded && (
                  <button
                    className="video-overlay-button"
                    onClick={() => {
                      navigate("/p/fan/lives?tab=schedule");
                    }}
                  >
                    {t("discover-lives")}
                  </button>
                )}
              </>
            ) : (
              <div>
                <img className="live-img" src={LiveGiftImg} alt="Live" />
                <button
                  className="video-overlay-button"
                  onClick={() => {
                    navigate("/p/fan/lives?tab=schedule");
                  }}
                >
                  {t("discover-lives")}
                </button>
              </div>
            )}
          </div>
          <MainSwiper />
          {homeFeeds.length === 0 && <FanHomeSuggestionComponent showSection4={true} />}
        </div>
        <FanPhoneModalComponent
          visible={phoneModal}
          onClose={() => {
            closePhoneModal();
          }}
        />
      </Layout.Content>
      <FooterComponent type="fan" page={FOOTER_IDS.HOME} />
    </Layout>
  );
};

export default FanHomePage;
