import { Col, Row, Space, Typography } from "antd";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClockIcon from "../../assets/icons/horloge.svg";
import VerifyIcon from "../../assets/icons/verify.svg";
import { LIVE_TYPES } from "../../helpers/constant";
import { getLivesForFan } from "../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../redux/store";
import liveUtil from "../../utls/LiveUtil";
import AvatarComponent from "../AvatarComponent";
import "./index.less";

const FanEventsListTabComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const { todayScheduleList, upcomingScheduleList } = useSelector((state: StoreState) => state.lives);

  useEffect(() => {
    if (searchParams.get("tab") === LIVE_TYPES.SCHEDULE) {
      dispatch(getLivesForFan({ startType: LIVE_TYPES.SCHEDULE }));
    }
  }, [searchParams, dispatch]);

  return (
    <Col className="fanEventsTabContent">
      <Space className="w-full" direction="vertical">
        <Row>
          <Typography.Text className="font-30-bold text-white-color">{t("today-events")}</Typography.Text>
        </Row>
        <div className="todayLiveSectionContainer">
          {!isEmpty(todayScheduleList) ? (
            todayScheduleList?.map((event, index) => {
              return (
                <div className="todayLiveSectionItem" key={event.creator!._id} onClick={() => navigate(`/fan/event/${event._id}`)}>
                  <div className="todayLiveSectionImageWrapper">
                    {typeof event.scheduleData?.media === "object" && (
                      <img src={event.scheduleData?.media?.absolutePath} alt={`event-${index}}`} className="todayLiveImage" />
                    )}
                    {event.scheduleData?.dateTime && (
                      <div className="todayLiveHoursOverlay">
                        <img src={ClockIcon} width={12} alt="" />
                        <Typography.Text className="todayLiveHours">
                          {liveUtil.getScheduleDayTimeOnlyHours(t, event.scheduleData.dateTime)}
                        </Typography.Text>
                      </div>
                    )}
                    <div className="todayLiveInfoOverlay">
                      <Row className="nameRow">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate("/p/" + event?.creator?.username);
                          }}
                        >
                          <AvatarComponent image={event.creator?.avatar} size={32} />
                        </div>
                        <Typography.Text className="todayLiveTitle ellipsis-single-line ml-10">{event?.creator?.username}</Typography.Text>
                      </Row>

                      {event.scheduleData?.message && (
                        <Typography.Text className="w-full todayLiveDescription ellipsis-single-line">{event.scheduleData?.message}</Typography.Text>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <Typography.Text className="text-white-color noEventsMessage">{t("no-scheduled-events-message")}</Typography.Text>
          )}
        </div>
      </Space>
      <Space className="scheduleLiveSectionContainer" direction="vertical">
        <Row>
          <Typography.Text className="font-30-bold text-white-color">{t("upcoming")}</Typography.Text>
        </Row>
        <Row gutter={14} className="row-gap-10">
          {!isEmpty(upcomingScheduleList) ? (
            upcomingScheduleList?.map((event, index) => {
              return (
                <Col key={index} span={12} onClick={() => navigate(`/fan/event/${event._id}`)}>
                  <Space className="w-full" direction="vertical">
                    <Row>
                      <Space className="gap-6">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate("/p/" + event?.creator?.username);
                          }}
                        >
                          <AvatarComponent image={event.creator?.avatar} size={32} />
                        </div>
                        <Col>
                          <Space className="gap-1" direction="vertical">
                            <Row>
                              <Space className="gap-4">
                                <Typography.Text className="font-14-bold text-white-color">{event.creator?.username}</Typography.Text>
                                {event.creator?.verifiedEmail && <img width={11} src={VerifyIcon} alt="verify" />}
                              </Space>
                            </Row>
                            <Row>
                              <Typography.Text className="font-13-light">
                                {event.scheduleData?.dateTime && liveUtil.getScheduleDayTimeString(t, event.scheduleData.dateTime)}
                              </Typography.Text>
                            </Row>
                          </Space>
                        </Col>
                      </Space>
                    </Row>
                    <div className="upcomingLiveSectionImageWrapper">
                      {typeof event.scheduleData?.media === "object" && (
                        <img src={event.scheduleData?.media?.absolutePath} alt={`event-${index}}`} className="upcomingLiveImage" />
                      )}
                      <div className="upcomingLiveInfoOverlay">
                        <Typography.Text className="w-full upcomingLiveTitle ellipsis-single-line">{event?.creator?.username}</Typography.Text>

                        {event.scheduleData?.message && (
                          <Typography.Text className="w-full upcomingLiveDescription ellipsis-single-line">
                            {event.scheduleData?.message}
                          </Typography.Text>
                        )}
                      </div>
                    </div>
                  </Space>
                </Col>
              );
            })
          ) : (
            <Typography.Text className="text-white-color noEventsMessage">{t("no-upcoming-events-message")}</Typography.Text>
          )}
        </Row>
      </Space>
    </Col>
  );
};

export default FanEventsListTabComponent;
