import { Button, Layout, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import VerifyIcon from "../../../assets/icons/verify.svg";
import { ILive } from "../../../helpers/types";
import { useNavigate } from "react-router-dom";
import "./index.less";
import AvatarComponent from "../../AvatarComponent";
import { liveIsPrivate } from "../../../utls/FunctionsUtil";
import { FanPaymentVerify } from "../../FanPaymentVerifyComponent/FanPaymentVerify";

type FanLiveWhoLikesItProps = {
  lives: ILive[];
};

const FanLiveWhoLikesItComponent: React.FC<FanLiveWhoLikesItProps> = ({ lives }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickLive = (live: ILive) => {
    if (live.isPaymentVerified) {
      navigate(`/fan/live/${live._id}`);
    }
  };

  return (
    <Layout className="background-transparent">
      <Space direction="vertical" className="fanDirectLiveWhoLikesItWrapper gap-1">
        <Row>
          <Typography.Text className="liveWhoLikesItTitle">{t("live-that-you-might-like")}</Typography.Text>
        </Row>
        <div className="liveWhoLikesItContainer">
          {lives.map((live, index) => {
            const isPrivate = liveIsPrivate(live);

            return (
              <div className="liveWhoLikesItItem" key={index} onClick={() => onClickLive(live)}>
                <div className="liveWhoLikesItImageWrapper">
                  <img src={live.creator?.avatar} className={`liveWhoLikesItImage ${isPrivate ? "blurImage" : ""}`} />
                  <div className="liveWhoLikesItTopInfo">
                    <div className="liveWhoLikesItTopInfoUsernameContainer">
                      <Typography.Text className="w-full liveWhoLikesItTopInfoUsername">@{live.creator?.username}</Typography.Text>
                    </div>
                  </div>
                  {isPrivate && <FanPaymentVerify live={live} />}
                  <div className="liveWhoLikesItInfoOverlay">
                    <Row className="liveWhoLikesItInfoContainer">
                      <AvatarComponent image={live.creator?.avatar} size={18} />
                      <Row className="liveWhoLikesItInfoContainer">
                        <Typography.Text className="w-full liveWhoLikesItName ellipsis-single-line">{live.creator?.name}</Typography.Text>
                        <img src={VerifyIcon} />
                      </Row>
                    </Row>

                    {live.scheduleData?.message && (
                      <Typography.Text className="w-full liveWhoLikesItDescription ellipsis-single-line">
                        {live.scheduleData?.message}
                      </Typography.Text>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Space>
    </Layout>
  );
};

export default FanLiveWhoLikesItComponent;
