import { Button, Col, message, Modal, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import CopyLinkIcon from "../../assets/icons/fan/shareModal/copy-link.webp";
import MessageIcon from "../../assets/icons/fan/shareModal/message.webp";
import TelegramIcon from "../../assets/icons/fan/shareModal/telegram.webp";
import TwitterIcon from "../../assets/icons/fan/shareModal/twitter.webp";
import WhatsAppIcon from "../../assets/icons/fan/shareModal/whatsapp.webp";
import { copyToClipboard } from "../../utls/FunctionsUtil";
import "./index.less";

type props = {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
  link?: string;
  username?: string;
};

const ShareModalComponent: React.FC<props> = ({ isOpen, onClose, id, link, username }) => {
  const { t } = useTranslation();

  const url = link !== undefined ? link : `${window.location.origin}/p/${username}`;

  const copyClipBoardClicked = async () => {
    await copyToClipboard(url);
    await message.success(t("link-copied"));
  };

  const openSMSApp = () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isMobile) {
      const encodedMessage = encodeURIComponent(url);
      window.location.href = `sms:?body=${encodedMessage}`;
    }
  };

  const socialLinks = [
    /*{
      name: "Instagram",
      element: <Col key={0} className="d-contents justify-content-center" span={6}>
        <InstapaperShareButton
          url={url}
          children={
            <Space
              className="gap-2"
              direction="vertical"
              align="center"
            >
              <img src={InstagramIcon} alt="instagram icon" />
              <Typography.Text className="shareModalSocialLinkLabel">
                {t("instagram")}
              </Typography.Text>
            </Space>
          }
        />
      </Col>,
    },*/
    {
      name: "WhatsApp",
      element: (
        <Col key={1} className="d-contents justify-content-center" span={6}>
          <WhatsappShareButton
            url={url}
            children={
              <Space className="gap-2" direction="vertical" align="center">
                <img src={WhatsAppIcon} alt="whatsapp icon" width={48} />
                <Typography.Text className="shareModalSocialLinkLabel">{t("whatsapp")}</Typography.Text>
              </Space>
            }
          />
        </Col>
      ),
    },
    {
      name: "Twitter",
      element: (
        <Col key={2} className="d-contents justify-content-center" span={6}>
          <TwitterShareButton
            url={url}
            children={
              <Space className="gap-2" direction="vertical" align="center">
                <img src={TwitterIcon} alt="twitter icon" width={48} />
                <Typography.Text className="shareModalSocialLinkLabel">{t("twitter")}</Typography.Text>
              </Space>
            }
          />
        </Col>
      ),
    },
    {
      name: "Telegram",
      element: (
        <Col key={3} className="d-contents justify-content-center" span={6}>
          <TelegramShareButton
            url={url}
            children={
              <Space className="gap-2" direction="vertical" align="center">
                <img src={TelegramIcon} width={48} alt="telegram icon" />
                <Typography.Text className="shareModalSocialLinkLabel">{t("telegram")}</Typography.Text>
              </Space>
            }
          />
        </Col>
      ),
    },
    {
      name: "Message",
      element: (
        <Col key={4} className="d-contents justify-content-center" span={6}>
          <Space className="gap-2" direction="vertical" align="center" onClick={openSMSApp}>
            <img src={MessageIcon} alt="message icon" width={48} />
            <Typography.Text className="shareModalSocialLinkLabel">{t("message")}</Typography.Text>
          </Space>
        </Col>
      ),
    },
    {
      name: "Copier le lien",
      element: (
        <Col key={5} className="d-contents justify-content-center" span={6}>
          <Space className="gap-2" direction="vertical" align="center" onClick={copyClipBoardClicked}>
            <img src={CopyLinkIcon} alt="copy icon" width={48} />
            <Typography.Text className="shareModalSocialLinkLabel">{t("copy-link")}</Typography.Text>
          </Space>
        </Col>
      ),
    },
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="shareModalWrapper"
      title={t("share-profile-via")}
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <Row className="cursor-pointer justify-content-center" onClick={onClose}>
          <Button type="link">{t("cancel")}</Button>
        </Row>
      }
    >
      <Row className="justify-content-center gap-10" gutter={8}>
        {socialLinks.map((item, index) => {
          return item.element;
        })}
      </Row>
    </Modal>
  );
};

export default ShareModalComponent;
