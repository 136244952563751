import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { LIVE_TYPES } from "../../helpers/constant";
import { ILive, ILiveState, ISearchLivesPayload } from "../../helpers/types";
import { liveService } from "../../services";
import { WritableDraft } from "immer/dist/internal";

const initialState: ILiveState = {
  list: [],
  todayScheduleList: [],
  upcomingScheduleList: [],
  discoverList: [],
  suggestedList: [],
  item: null,
  token: null,
  loading: false,
  error: null,
};

export const publishLive = createAsyncThunk("lives/publishLive", async (data: FormData) => {
  return liveService.publishLive(data);
});

export const getLiveById = createAsyncThunk("lives/getLiveById", async (id: string) => {
  return liveService.getLiveById(id);
});

export const buildToken = createAsyncThunk("lives/buildToken", async (sessionId: string) => {
  return liveService.buildToken(sessionId);
});

export const getLivesForFan = createAsyncThunk("lives/getLivesForFan", async (params: ISearchLivesPayload = { startType: LIVE_TYPES.NOW }) => {
  return liveService.getLivesForFan(params);
});

export const getPublicScheduleLive = createAsyncThunk("lives/getPublicScheduleLive", async () => {
  return liveService.getPublicScheduleLive();
});

// export const join = createAsyncThunk("lives/join", async (sessionId: string) => {
//   return liveService.join(sessionId);
// });

export const getCreatorScheduleLive = createAsyncThunk("lives/creator/schedule", async (id: string) => {
  return liveService.getCreatorScheduleLive(id);
});

export const getReservations = createAsyncThunk("lives/getReservations", async (id: string) => {
  return liveService.getReservationsCount(id);
});

export const getTotalPrice = createAsyncThunk("lives/getTotalPrice", async (id: string) => {
  return liveService.getTotalPrice(id);
});

export const deleteLive = createAsyncThunk("lives/delete", async (id: string) => {
  return liveService.deleteLive(id);
});

const livesSlice = createSlice({
  name: "lives",
  initialState,
  reducers: {
    editLive: (state, action) => {
      state.item = action.payload;
    },
    updateLives: (state, action) => {
      state.list = action.payload;
    },
    updateEndLive: (state, action) => {
      const lives = [...state.discoverList, ...state.suggestedList];
      const liveToEdit = lives.find((item) => item._id === action.payload.streamId);
      if (liveToEdit) {
        if (action.payload.isStreamingEnds) {
          if (liveToEdit.isSuggested) {
            state.suggestedList = state.suggestedList.filter((live) => live._id !== action.payload.streamId);
          } else {
            state.discoverList = state.discoverList.filter((live) => live._id !== action.payload.streamId);
          }
        }
      }
      return state;
    },
    // followCreator: (state, action) => {
    //   const newLives =
    //     state.list &&
    //     state.list.map((live) => {
    //       if (live.creator?._id === action.payload.id) {
    //         live.isFollowed = action.payload.isFollowed;
    //       }
    //       return live;
    //     });
    //   state.list = newLives;
    // },
    // unfollowCreator: (state, action) => {
    //   const newLives =
    //     state.list &&
    //     state.list.map((live) => {
    //       if (live.creator?._id === action.payload.id) {
    //         live.isFollowed = action.payload.isFollowed;
    //       }
    //       return live;
    //     });
    //   state.list = newLives;
    // },
    clearLiveData: (state) => {
      state.item = null;
      state.token = null;
    },
    clearToken: (state) => {
      state.token = null;
    },
    updateLivePayment: (state, action) => {
      const liveListToEdit = action.payload.isSuggested ? "suggestedList" : "discoverList";
      if (state[liveListToEdit]) {
        const liveIndex = state[liveListToEdit].findIndex((live) => live._id === action.payload.id);
        if (liveIndex !== -1) {
          state[liveListToEdit][liveIndex].isPaymentVerified = true;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(buildToken.pending, (state, action) => {
      state.token = null;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(buildToken.fulfilled, (state, action: any) => {
      state.token = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(buildToken.rejected, (state, action) => {
      state.token = null;
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getLivesForFan.pending, (state, action) => {
      state.list = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLivesForFan.fulfilled, (state, action: any) => {
      if (action.payload.startType === LIVE_TYPES.SCHEDULE) {
        state.todayScheduleList = action.payload.data.today;
        state.upcomingScheduleList = action.payload.data.upcoming;
      } else {
        // state.list = action.payload.data;
        state.discoverList = action.payload.data.discover;
        state.suggestedList = action.payload.data.suggested;
      }
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getLivesForFan.rejected, (state, action) => {
      state.list = [];
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getPublicScheduleLive.pending, (state, action) => {
      state.list = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPublicScheduleLive.fulfilled, (state, action: any) => {
      state.todayScheduleList = action.payload.data.today;
      state.upcomingScheduleList = action.payload.data.upcoming;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPublicScheduleLive.rejected, (state, action) => {
      state.list = [];
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getReservations.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getReservations.fulfilled, (state, action: any) => {
      state.loading = false;
      state.item = { ...state.item, reservations: action.payload };
    });
    builder.addCase(getReservations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTotalPrice.fulfilled, (state, action: any) => {
      state.item = { ...state.item, totalPrice: action.payload };
    });
    builder.addCase(deleteLive.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteLive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteLive.fulfilled, (state, action) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(publishLive.pending, getLiveById.pending, getCreatorScheduleLive.pending), (state, action) => {
      state.item = null;
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(publishLive.fulfilled, getLiveById.fulfilled, getCreatorScheduleLive.fulfilled), (state, action) => {
      state.item = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(publishLive.rejected, getLiveById.rejected, getCreatorScheduleLive.rejected), (state, action) => {
      state.item = null;
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { editLive, updateLives, updateEndLive, clearToken, clearLiveData, updateLivePayment } = livesSlice.actions;

export default livesSlice.reducer;
