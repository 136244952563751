import { Button, Layout, Row, Space, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { ILive } from "../../../helpers/types";
import { useNavigate } from "react-router-dom";
import "./index.less";
import { FanPaymentVerify } from "../../FanPaymentVerifyComponent/FanPaymentVerify";
import { liveIsPrivate } from "../../../utls/FunctionsUtil";

type FanDiscoverLiveProps = {
  lives: ILive[];
};

const FanDiscoverLiveComponent: React.FC<FanDiscoverLiveProps> = ({ lives }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickLive = (live: ILive) => {
    if (live.isPaymentVerified) {
      navigate(`/fan/live/${live._id}`);
    }
  };

  return (
    <Layout className="background-transparent">
      <Space direction="vertical" className="fanDirectDiscoverLiveWrapper gap-1">
        <Row>
          <Typography.Text className="discoverLiveTitle mt-10">{t("Discover-label")}</Typography.Text>
        </Row>
        <div className="discoverLiveContainer">
          {lives.map((live, index) => {
            const isPrivate = liveIsPrivate(live);

            return (
              <div className="discoverLiveItem" key={index} onClick={() => onClickLive(live)}>
                <div className="discoverLiveImageWrapper">
                  <img src={live.creator?.avatar} className={`discoverLiveImage ${isPrivate ? "blurImage" : ""}`} />
                  <div className="discoverLiveTopInfo">
                    <Tag className="liveTag">{t("live").toUpperCase()}</Tag>
                  </div>
                  {isPrivate && <FanPaymentVerify live={live} />}
                  <div className="discoverLiveInfoOverlay">
                    <Typography.Text className="w-full discoverLiveName ellipsis-single-line">{live.creator?.name}</Typography.Text>
                    {live.scheduleData?.message && (
                      <Typography.Text className="w-full discoverLiveDescription ellipsis-single-line">{live.scheduleData?.message}</Typography.Text>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Space>
    </Layout>
  );
};

export default FanDiscoverLiveComponent;
