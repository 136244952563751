import axios from "../helpers/axios";
import { LIVE_TYPES } from "../helpers/constant";
import { ILive, ILiveTip, ISearchLivesPayload, IStripeLivePayload, IStripeLiveTipPayload } from "../helpers/types";

class LiveService {
  public publishLive(data: FormData) {
    const url = `/streaming/live`;
    return new Promise<ILive>((resolve, reject) => {
      axios
        .post(url, data)
        .then((response) => {
          if (response?.data?.data?.sessionId) resolve(response.data.data);
          else {
            if (
              response?.data?.data?.error &&
              (response.data.data.error === "live-daily-quota-consumed" || response.data.data.error === "programmed-live-already-exist")
            )
              reject(response.data.data.error);
            else reject("Something went wrong");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public getLiveById(id: string) {
    const url = `/streaming/live/${id}`;

    return new Promise<ILive>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public buildToken(sessionId: string, isRTMP: boolean = false): Promise<string> {
    const url = `/streaming/agora/token`;
    return new Promise((resolve, reject) => {
      axios
        .post(url, { channelName: sessionId, isRTMP })
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public getLivesForFan(params: ISearchLivesPayload = { startType: LIVE_TYPES.NOW }) {
    const url = `/streaming/lives/search/fan`;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params,
        })
        .then((response) => {
          if (response?.data?.data) resolve({ startType: params.startType, data: response.data.data });
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public getPublicScheduleLive() {
    const url = `/streaming/lives/schedule/public`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {          
          if (response?.data) resolve(response.data );
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public join(sessionId: string) {
    const url = `/streaming/join/${sessionId}`;
    return new Promise<ILive>((resolve, reject) => {
      axios
        .post(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public getCreatorScheduleLive(id: string) {
    const url = `/streaming/lives/schedule/creator/${id}`;
    return new Promise<ILive | null>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public deleteLive(id: string) {
    const url = `/streaming/live/${id}`;
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(url)
        .then((response) => {
          if (response?.data?.data?.success) resolve();
          else reject("Something went wrong.");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public verifyPayment(id: string, fanId: string) {
    const url = `/streaming/verify/payment`;
    return new Promise<boolean>((resolve, reject) => {
      axios
        .post(url, { id, fanId })
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public getReservationsCount(id: string) {
    const url = `/streaming/live/reservationsCount/${id}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          resolve(res?.data?.data);
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  }

  public getTotalPrice(id: string) {
    const url = `/streaming/live/totalPrice/${id}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          resolve(res?.data?.data);
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  }

  public stripeLive(body: IStripeLivePayload) {
    const url = `/payment/stripe/live`;
    return new Promise((resolve, reject) => {
      axios
        .post(url, body)
        .then((res) => {
          if (res?.data?.data) {
            resolve(res.data.data);
          } else {
            resolve(res.data);
          }
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  }

  public createTip(id: string, amount: number) {
    const url = `/streaming/live/tip/${id}`;
    return new Promise<ILiveTip>((resolve, reject) => {
      axios
        .post(url, { amount })
        .then((res) => {
          resolve(res?.data?.data);
        })
        .catch((error) => {
          reject(error?.message);
        });
    });
  }

  public getTipById(id: string) {
    const url = `/streaming/live/tip/${id}`;
    return new Promise<ILiveTip>((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          if (res?.data?.data) resolve(res.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          reject(error?.message);
        });
    });
  }

  public stripeLiveTip(body: IStripeLiveTipPayload) {
    const url = `/payment/stripe/live/tip`;
    return new Promise((resolve, reject) => {
      axios
        .post(url, body)
        .then((res) => {
          if (res?.data?.data) {
            resolve(res.data.data);
          } else {
            resolve(res.data);
          }
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  }
}

export const liveService = new LiveService();
